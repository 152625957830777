document.getElementById("DOMContentLoaded", function () {
    var navbar = document.getElementById("navbarNav");
    navbar.classList.remove("show");
});

document.addEventListener('DOMContentLoaded', function () {

    var myNavbar = document.getElementById('navbarNav');
    var navbarToggle = document.querySelector('.navbar-toggler');

    
   
  });