import React from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import '../css/navigacne.css';
import '../js/navigacny.js';
import '../css/uvod.css';
import '../css/game.css';

import { withTranslation } from 'react-i18next';
import LanguageSwitcher from '../js/LanguageSwitcher';
import { v4 as uuidv4 } from 'uuid'; 

import imageLogo from "../images/logo1.png";
import ruka from "../images/ruka.png";
import pozadie from "../images/pozadie.jpeg";
import phone from "../images/phone.png";
import phoneon from "../images/phoneon.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ChatButtons from '../js/ChatButtons';

class Game extends React.Component {


  state = {
    languageChanged: false,
    isGameStarted: false,
    chats: [],
    isPhoneOn: false,
    messageMode: 'welcome', 
    loadedChats: [],
    currentChat: null,
    previousChat: null,
    previousChatId: null,
    user1: 'user1',
    user2: 'user2',
    userId: 0,
    score: 0,
    chatCount: 0,
    maxChats: 10,
    gameOver: false,

  };

 

  componentDidMount() {
    this.initializeSession();
    const storedUserId = localStorage.getItem('userId'); // Get userId from local storage
    if (storedUserId) {
      this.setState({ userId: parseInt(storedUserId, 10) }); // Set it to your state
    }
  }
  initializeSession = () => {
    const newSessionId = uuidv4();
    this.setState({ sessionId: newSessionId });
  }
  incrementUserId = () => {
    this.setState(prevState => ({
      userId: prevState.userId + 1
    }), () => {
      localStorage.setItem('userId', this.state.userId.toString()); // Store updated userId in local storage
    });
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== prevProps.i18n.language) {
      this.setState({ languageChanged: true });
    }
  
  }

  toggleDescription = () => {
    this.setState((prevState) => ({
      isPhoneOn: !prevState.isPhoneOn,
      showGameDescription: !prevState.isPhoneOn 
    }));
  };




  togglePhone = () => {
    console.log('Toggling phone, current state:', this.state.isPhoneOn);  // Log state before toggle
    this.setState((prevState) => ({
      isPhoneOn: !prevState.isPhoneOn,
    }), async () => {
      console.log('Phone toggled, new state:', this.state.isPhoneOn);  // Log state after toggle
      if (this.state.isPhoneOn) {
        this.incrementUserId();
        await this.handleFetchChats();
      }
    });
  };

  updateScore = (message, grooming) => {
    let increment = 0;
    if (message === 'No' && grooming === 'no') {
      increment = 1;  // Correct non-grooming response
    } else if (message !== 'No' && grooming === 'yes') {
      increment = 1;  // Correctly identified grooming scenario
    }
  
    this.setState(prevState => ({
      score: prevState.score + increment
    }));
  }

  submitScore = async (gender) => {
    const { userId, sessionId } = this.state;
    try {
      const response = await fetch('https://patrik.gajdos.website.tuke.sk/submit-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          session_id: sessionId,
          gender: gender
        })
      });
      const result = await response.json();
      console.log("Score submitted:", result);
    } catch (error) {
      console.error('Error submitting score:', error);
    }
  };
  
  
  handleSendMessage = async (message) => {
    const { currentChat, chatCount, maxChats } = this.state;
    if (!currentChat || chatCount >= maxChats) {
      console.error("No active chat to send message or maximum chats reached");
      return;
    }
  
    const messageData = {
      id_chatu: currentChat.id_chatu,
      message: message,
      grooming: currentChat.grooming,
      user_id: this.state.userId,
      session_id: this.state.sessionId
    };
  
    try {
      const response = await fetch('https://patrik.gajdos.website.tuke.sk/game/chats', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Message sent:", data);
  
      // Update the score
      this.updateScore(message, currentChat.grooming);
  
      // Update chat count and check for end of game
      this.setState(prevState => ({
        chatCount: prevState.chatCount + 1
      }), () => {
        if (this.state.chatCount >= this.state.maxChats) {
          this.setState({ gameOver: true });
        }
      });
  
    } catch (error) {
      console.error('Error sending message:', error);
    }
    this.handleFetchChats();
  };
  
  

  



handleFetchChats = async () => {
    try {
      const response = await fetch('https://patrik.gajdos.website.tuke.sk/game/chats');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Invalid content type. Expected JSON.');
      }
      const fetchedChats = await response.json();

      this.setState({ 
      chats: fetchedChats,
      currentChat: fetchedChats.length > 0 ? fetchedChats[0] : null,
  });
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };


  
  



  
  
  

  toggleMessageMode = () => {
    this.setState(prevState => ({
      messageMode: prevState.messageMode === 'welcome' ? 'gameDescription' : 'welcome'
    }));
  };


  render() {
    const { isPhoneOn, messageMode , chats, previousChat, handleSendMessage} = this.state;
    const { t } = this.props;
    
    
    

    return (
      <>
      {this.state.gameOver && (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('finaltext')}</h5>
                      
                    </div>
                    <div className="modal-body">
                        <p class="modal-text">{t('finaltext2')}{this.state.score} {t('finaltext3')} {this.state.maxChats}.</p>
                        <p class="modal-text">{t('finaltext4')}</p>
                        <div class="button-grid">
                            <button class="modal-button" onClick={() => {
                                 this.submitScore('Boy (7-9 years)');
                                 this.setState({ gameOver: false }, window.location.reload());
                                }}>{t('finaltext5')}</button>
                              <button class="modal-button" onClick={() => {
                                 this.submitScore('Boy (10-12 years)');
                                 this.setState({ gameOver: false }, window.location.reload());
                                }}>{t('finaltext6')}</button>
                              <button class="modal-button" onClick={() => {
                                 this.submitScore('Boy (13-15 years)');
                                 this.setState({ gameOver: false }, window.location.reload());
                                }}>{t('finaltext7')}</button>
                              <button class="modal-button" onClick={() => {
                                 this.submitScore('Girl (7-9 years)');
                                 this.setState({ gameOver: false }, window.location.reload());
                                }}>{t('finaltext8')}</button>
                              <button class="modal-button" onClick={() => {
                                 this.submitScore('Girl (10-12 years)');
                                 this.setState({ gameOver: false }, window.location.reload());
                                }}>{t('finaltext9')}</button>
                              <button class="modal-button" onClick={() => {
                                 this.submitScore('Girl (13-15 years)');
                                 this.setState({ gameOver: false }, window.location.reload());
                                }}>{t('finaltext10')}</button>
                       </div>
                    </div>
                    <div className="modal-footer">
                    
                    </div>
                </div>
            </div>
        </div>
    )}


      
      <div>
        <nav className="navbar navbar-expand-sm navbar-light">
          <NavLink className="navbar-brand" to="/">
            <img src={imageLogo} alt="Logo" />
          </NavLink>
          <form className="form-inline ml-auto custom-search">
        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder=""
          aria-label="Search"
          style={{ color: 'black', borderColor: 'black', width: '150px' }}
        />
        <button
          className="btn btn-outline-success my-2 my-sm-0 d-inline-block"
          type="submit"
          style={{ color: 'black', borderColor: 'black', backgroundColor: 'transparent' }}
        >{t('search')}
        </button>
   
      </form>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/" style={{ color: 'black' }}>{t('nav.home')}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/aboutUs" style={{ color: 'black' }}>{t('nav.aboutUs')}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/game" style={{ color: 'black' }} onClick={(e) => { 
                
                    window.location.reload();
                  }}
                  
                  
                  >
                  {t('nav.game')} </NavLink>
              </li>
            </ul>
          </div>
          <LanguageSwitcher />
        </nav>
        <div className="main-container" style={{ backgroundImage: `url(${pozadie})`, backgroundSize: 'cover' }}>
          <div className="phone">
            {!isPhoneOn && messageMode === 'welcome' &&  (
              <div className="welcome-message">
                <p className="welcome-line1">{t('welcome-line1')}</p>
                <p className="welcome-line2">{t('welcome-line2')}</p>
                <p className="welcome-line3">{t('welcome-line3')}</p>
                <p className="welcome-line4">{t('welcome-line4')}</p>
                <p className="welcome-line5">{t('welcome-line5')}</p>
              </div>
              )}
              {!isPhoneOn && messageMode === 'gameDescription' && (
              <div className="game-description">
                <p className="game-desc-line1">{t('game-desc-line1')}</p>
                <p className="game-desc-line2">{t('game-desc-line2')}</p>
                <p className="game-desc-line3">{t('game-desc-line3')}</p>
                <p className="game-desc-line4">{t('game-desc-line4')}</p>
                <p className="game-desc-line5">{t('game-desc-line5')}</p>
                <p className="game-desc-line6">{t('game-desc-line6')}</p>
                <p className="game-desc-line7">{t('game-desc-line7')}</p>
                <p className="game-desc-line8">{t('game-desc-line8')}</p>
                <button className="start-button" onClick={this.togglePhone}>
                {t('start-button')}
                </button>
              </div>
            )}
            <img src={phone} alt="Phone" />
            {isPhoneOn && (
            <div className="centered-container">
              <img src={phoneon} alt="Phonescreen" />
              <div className="chats">
                {this.state.chats.map((chat, index) => (
                  <div key={index} className={chat.user1 ? 'sender' : 'recipient'}>
                    <p className="sprava">{chat.sprava}</p>
                  </div>
                ))}

                
              </div>
              

              {isPhoneOn && (
                <div className="button-container">
                <ChatButtons 
                  handleSendMessage={this.handleSendMessage}
                  handleFetchChats={this.handleFetchChats}
                  setCurrentChat={this.setCurrentChat}
                  chats={this.state.chats}   />
                </div>
              )}
            </div>
          )}
            
          </div>
          <img src={ruka} alt="Ruka" className="ruka-image" />
          <button className="btn btn-primary centered-button" onClick={this.toggleMessageMode}>
          {messageMode === 'welcome' ? t('show_game_description') : t('show_welcome_message')}
          </button>
        </div>
      </div>
      </>
    );
    
  }
  
  
}

export default withTranslation()(Game);
