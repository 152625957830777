import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Uvod from './Uvod.js';
import AboutUs from './aboutUs.js';
import Game from './game.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Uvod />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/game" element={<Game />} />
   
    </Routes>
  );
}

export default App;