import React from "react";
import {NavLink} from "react-router-dom";
import '../css/navigacne.css';
import '../js/navigacny.js';
import '../css/uvod.css';
import { withTranslation } from 'react-i18next';
import LanguageSwitcher from '../js/LanguageSwitcher'; 

import imageLogo from "../images/logo1.png"; 

import imageKids from "../images/kids.jpeg"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


class Uvod extends React.Component {
    
 
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      languageChanged: false,
    };
    this.contactFormRef = React.createRef();
  }
  
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }
  
  handleSubmit = (event) => {
    event.preventDefault(); 
  
    const { name, email, message } = this.state;
  

    fetch('http://147.232.205.60:4000/submit-contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
   
      this.setState({
        name: '',
        email: '',
        message: ''
      });
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
  

  componentDidUpdate(prevProps) {
    if (this.props.i18n.language !== prevProps.i18n.language) {
      this.setState({ languageChanged: true });
    }
    if (this.state.languageChanged) {
      this.setState({ languageChanged: false });
    }
  } 
  scrollToContactForm = () => {
    this.contactFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  

    render() {
      const { t } = this.props;
      console.log('Translated text:', t('nav.home'));

      
    return (
    
      <div>
        
        <nav className="navbar navbar-expand-sm navbar-light">
      
          <NavLink className="navbar-brand" to="/">
            <img src={imageLogo} alt="Logo" />
          </NavLink>
   
          <form className="form-inline ml-auto custom-search">
    <input
      className="form-control mr-sm-2"
      type="search"
      placeholder=""
      aria-label="Search"
      style={{ color: 'black', borderColor: 'black', width: '150px' }}
    />
    <button
      className="btn btn-outline-success my-2 my-sm-0 d-inline-block"
      type="submit"
      style={{ color: 'black', borderColor: 'black', backgroundColor: 'transparent' }}
    >{t('search')}
    </button>
  </form>

<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
              <NavLink className="nav-link" to="/" style={{ color: 'black' }}>{t('nav.home')}</NavLink>
              </li>
              <li className="nav-item">
                    <NavLink className="nav-link" to="/aboutUs" style={{ color: 'black' }}>{t('nav.aboutUs')}</NavLink>
              </li>
              <li className="nav-item">
                    <NavLink className="nav-link" to="/game" style={{ color: 'black' }}>{t('nav.game')}</NavLink>
              </li>
              
              
            </ul>
            
          </div>

          <LanguageSwitcher />
        </nav>
        <div className="partial-height-background" style={{ background: `url(${imageKids}) center center / cover no-repeat` }}>
          <h1 className="textik">{t('textik')}</h1>
          <h2 className="textik2">{t('textik2')}</h2>
          <button className="join-button"onClick={this.scrollToContactForm}>{t('join-button')}</button>
        </div>
  
        <div className="con">
          <div className="con1">
            <div className="about-us-container">
              <h1 className="about-us-title">{t('about-us-title')}</h1>
              <p className="about-us-text">{t('about-us-text')}</p>
              <NavLink className="nav-link" to="/aboutUs" style={{ color: 'black' }}>
               <button className="read-more-button">{t('read-more-button')}</button>
             </NavLink>
            </div>
  
            <div className="empty-block">
              <h1 className="about-us-title">{t("news.title")}</h1>
              <p className="about-us-text">{t("news.text")}</p>
              <button className="read-more-button">{t('read-more-button')}</button>
            </div>
          </div>
  
          <div className="empty-block2" ref={this.contactFormRef}>
            <h2 className="empty-block2-title">{t('contactUs.title')}</h2>
            <form onSubmit={this.handleSubmit} id="contact-form">

            <input
                  type="text"
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  required 
              />

            <label htmlFor="email">{t('contactUs.email')}</label>
              <input
                    type="email"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required 
              />

            <label htmlFor="message">{t('contactUs.message')}</label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                    required
              />

            <button type="submit">{t('contactUs.submit')}</button>
          </form>
          </div>
          
        </div>
       
        <div className="empty-block3" style={{ marginTop: '10px' }}></div>
        
        <footer>
          <p>{t('footer.text')}</p>
        </footer>
      </div>
    )
  }
}
export default withTranslation()(Uvod);