import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import '../css/game.css';
import { withTranslation } from 'react-i18next';


const ChatButtons = ({ handleSendMessage, currentChat, setCurrentChat, handleFetchChats, chats, t }) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');

  const handleSend = async () => {
    try {
      await handleSendMessage(message);
    
    } catch (error) {
      console.error('Chyba pri odosielaní správy alebo pri načítavaní nového chatu:', error);
    } finally {
      handleCloseModal();

    }
  };


  const handleSendNoMessage = async () => {
    try {
      const fixedmessage = 'No';
      await handleSendMessage(fixedmessage);

    } catch (error) {
      console.error('Chyba pri odosielaní správy alebo pri načítavaní nového chatu:', error);
    } 
  };
  const handleSendHarassment = async () => {
    try {
      const fixedmessage = 'Obťažovanie';
      await handleSendMessage(fixedmessage);
      handleCloseModal();

    } catch (error) {
      console.error('Chyba pri odosielaní správy alebo pri načítavaní nového chatu:', error);
    } 
  };
  const handleSendBlackmail = async () => {
    try {
      const fixedmessage = 'Vydieranie';
      await handleSendMessage(fixedmessage);
      handleCloseModal();

    } catch (error) {
      console.error('Chyba pri odosielaní správy alebo pri načítavaní nového chatu:', error);
    } 
  };
  const handleSendManipulation = async () => {
    try {
      const fixedmessage = 'Manipulácia';
      await handleSendMessage(fixedmessage);
      handleCloseModal();

    } catch (error) {
      console.error('Chyba pri odosielaní správy alebo pri načítavaní nového chatu:', error);
    } 
  };
  
  

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      <Button variant="danger" className="chat-button" onClick={handleOpenModal}>
      {t('report')}
      </Button>
      <Button variant="success" className="chat-button" onClick={handleSendNoMessage}>
        <ArrowRight />
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title> {t('reason')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            placeholder=""
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal} style={{ flex: 1, backgroundColor: 'gray' }}>
          {t('close')}
          </Button>
          <Button variant="primary" onClick={handleSendHarassment} style={{ flex: 1 }}>
          {t('harassment')}
          </Button>
          <Button variant="primary" onClick={handleSendBlackmail} style={{ flex: 1 }}>
          {t('blackmail')}
          </Button>
          <Button variant="primary" onClick={handleSendManipulation} style={{ flex: 1 }}>
          {t('manipulation')}
          </Button>
          <Button variant="primary" onClick={handleSend} style={{ flex: 1 }}>
          {t('send')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withTranslation()(ChatButtons);
