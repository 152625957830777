
import React from 'react';
import {NavLink} from "react-router-dom";
import '../css/navigacne.css';
import '../js/navigacny.js';
import '../css/uvod.css';
import '../css/aboutUs.css';
import { withTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import imageLogo from "../images/logo1.png"; 
import mojaFoto from "../images/fotka.png";

class AboutUs extends React.Component{

    state = {
        languageChanged: false,
      };
    
      componentDidUpdate(prevProps) {
        if (this.props.i18n.language !== prevProps.i18n.language) {
  
          this.setState({ languageChanged: true });
        }
      } 
    
        render() {
          const { t } = this.props;
          console.log('Translated text:', t('nav.home'));
    
          if (this.state.languageChanged) {
            this.setState({ languageChanged: false });
          }
        return (
        
          <div>
           
            <nav className="navbar navbar-expand-sm navbar-light">
              <NavLink className="navbar-brand" to="https://patrik.gajdos.website.tuke.sk">
                <img src={imageLogo} alt="Logo" />
              </NavLink>
              <form className="form-inline ml-auto custom-search">
        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder=""
          aria-label="Search"
          style={{ color: 'black', borderColor: 'black', width: '150px' }}
        />
        <button
          className="btn btn-outline-success my-2 my-sm-0 d-inline-block"
          type="submit"
          style={{ color: 'black', borderColor: 'black', backgroundColor: 'transparent' }}
        >{t('search')}
        </button>
      </form>
    
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              
    
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                  <NavLink className="nav-link" to="/" style={{ color: 'black' }}>{t('nav.home')}</NavLink>
                  </li>
              
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/aboutUs" style={{ color: 'black' }}>{t('nav.aboutUs')}</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/game" style={{ color: 'black' }}>{t('nav.game')}</NavLink>
              </li>
                 
                </ul>
               
              </div>
              <LanguageSwitcher />
              
            </nav>

        <div className="about-container">
            <div className="about-header">
                <img className ="about-image"
                 src={mojaFoto}
                 alt="Vaša fotka"
             
                 />
                  <h1>Patrik Gajdoš</h1>
                  </div>
             <div className="about-text">
            
                 <p>
                 {t('aboutUs-text1')}
                 </p>
                    <p>
                    {t('aboutUs-text2')}
                    </p>
                        <p>
                        {t('aboutUs-text3')}
                       </p>
             </div>
        </div>
           
            <footer>
              <p>{t('footer.text')}</p>
            </footer>
          </div>
        )
      }
    }
    export default withTranslation()(AboutUs);

