import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; 
import '../css/language.css';

import enTranslation from '../translation/en.json';
import skTranslation from '../translation/sk.json';
import noTranslation from '../translation/no.json';

import SKflag from "../images/SK.png";
import NRflag from "../images/NR.png";
import ENflag from "../images/EN.png";


i18n
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {    
     
    },
    resources: {
      en: {
        translation: enTranslation,
      },
      sk: {
        translation: skTranslation,
      },
      no: {
        translation: noTranslation,
      },
    },
  });

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    console.log('Changing language to:', lng);
    try {
      await fetch('http://147.232.205.60:4000/game/language', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ language: lng }),  
      });
      i18n.changeLanguage(lng);
      localStorage.setItem('language', lng);
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  

  return (
    <div className="language-switch">
      <img
        src={ENflag}
        alt="English flag"
        className={`flag-icon ${i18n.language === 'en' ? 'active-flag' : ''}`}
        style={{ width: '30px', marginRight: '10px' }}
        onClick={() => changeLanguage('en')}
      />
      <img
        src={SKflag}
        alt="Slovak flag"
        className={`flag-icon ${i18n.language === 'sk' ? 'active-flag' : ''}`}
        style={{ width: '30px', marginRight: '10px' }}
        onClick={() => changeLanguage('sk')}
      />
      <img
        src={NRflag}
        alt="Norwegian flag"
        className={`flag-icon ${i18n.language === 'no' ? 'active-flag' : ''}`}
        style={{ width: '30px', marginRight: '10px' }}
        onClick={() => changeLanguage('no')}
      />
    </div>
  
  );
};

export default LanguageSwitcher;
